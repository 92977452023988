/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IEvent } from 'models';
import { Block, InnerBlock } from 'components/layout';
import {
    DatePicker,
    Form,
    Input,
    Switch,
    InputNumber,
    Col,
    Row,
    message,
    Button,
    Space,
    Upload,
    UploadFile,
    UploadProps,
    Modal,
    Select,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { EventService, FileService } from 'services';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import config from 'config';
import { useForm } from 'antd/es/form/Form';
import { userStore } from 'store';
import './event-info.scss';
import { RcFile } from 'antd/es/upload';
import { link } from 'components/layout/Icons/Icons';
import { BoldOutlined, ItalicOutlined } from '@ant-design/icons';
import {
    makeMarkdownLink,
    makeMarkdownBold,
    markdownChecker,
    removeBoldFormatting,
    removeItalicFormatting,
    makeMarkdownItalic,
} from 'lib/makeMarkdown';
import { changeFormFieldValue } from 'lib/changeFormFieldValue';
import { ISelectMarkdownOptions } from 'models/ISelectMarkdownOptions';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

const floors = [
    { value: '1 этаж', label: '1 этаж' },
    { value: '2 этаж', label: '2 этаж' },
];

interface Props {
    title?: string;
    data?: IEvent | null;
    loading?: boolean;
    isNew?: boolean;
}

const EventInfo = (props: Props) => {
    const { roles } = userStore;
    const { title, loading, data, isNew } = props;
    const [important, setImportant] = useState(false);
    const [secret, setSecret] = useState(false);
    const [singleLink, setSingleLink] = useState(false);
    const [requiredPersonalData, setRequiredPersonalData] = useState<boolean>(false);
    const [editEvent, setEditEvent] = useState<boolean>(false);
    const [customRegistration, setCustomRegistration] = useState<boolean>(false);
    const [form] = useForm(null);

    const [appFileList, setAppFileList] = useState<UploadFile[]>([]);
    const [siteFileList, setSiteFileList] = useState<UploadFile[]>([]);
    const [speakerFileList, setSpeakerFileList] = useState<UploadFile[]>([]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [avatartImgId, setAvatarImgId] = useState<string>(data?.speakerDto?.avatar);
    const [siteImgId, setSiteImgId] = useState<string>(data?.imageSiteId);
    const [appImgId, setAppImgId] = useState<string>(data?.imageAppId);

    useEffect(() => {
        setAvatarImgId(data?.speakerDto?.avatar);
        setSiteImgId(data?.imageSiteId);
        setAppImgId(data?.imageAppId);
    }, [data]);

    const handleAvatarImgRemove = () => {
        setAvatarImgId(null);
    };

    const handleSiteImgRemove = () => {
        setSiteImgId(null);
    };

    const handleAppImgRemove = () => {
        setAppImgId(null);
    };

    const navigate = useNavigate();

    const normalizeFormData = (rawData: IEvent) => {
        const newFormData = {
            ...rawData,
            datetimeStart: dayjs(rawData.datetimeStart).utc().second(0).format(),
            datetimeEnd: dayjs(rawData.datetimeEnd).utc().second(0).format(),
        };

        const ticketPrices = [];
        newFormData.imageAppId = appImgId || null;
        // appFileList[0]?.response?.id || appFileList[0].uid;
        newFormData.imageSiteId = siteImgId || null;
        // siteFileList[0]?.response?.id || siteFileList[0].uid;
        newFormData.speakerDto.avatar = avatartImgId || null;
        // speakerFileList[0]?.response?.id || speakerFileList[0]?.uid || null;

        newFormData.important = important;
        newFormData.secret = secret;
        newFormData.singleLink = singleLink;
        newFormData.requiredPersonalData = requiredPersonalData;
        newFormData.customRegistration = rawData.customRegistration;

        Object.values(newFormData.ticketPrices).forEach((value) => {
            const key = Object.keys(value);
            const price = Object.values(value);
            ticketPrices.push({
                role: key[0],
                ticketPrice: price[0] as number,
            });
            newFormData.ticketPrices = ticketPrices as [
                {
                    role: string;
                    ticketPrice: number;
                }
            ];
        });

        return newFormData;
    };

    const setInitValues = () => {
        if (data) {
            setImportant(data?.important);
            setSecret(data?.secret);
            setSingleLink(data.singleLink);
            setRequiredPersonalData(data.requiredPersonalData);
            setCustomRegistration(data?.customRegistration?.used);
        }

        if (data?.imageAppId) {
            FileService.getFileData(data.imageAppId).then((response) => {
                setAppFileList([
                    ...[],
                    {
                        uid: response.data.id,
                        name: response.data.originalFileName,
                        status: 'done',
                        url: response.data.link,
                    },
                ]);
            });
        }

        if (data?.imageAppId) {
            FileService.getFileData(data.imageSiteId).then((response) => {
                setSiteFileList([
                    ...[],
                    {
                        uid: response.data.id,
                        name: response.data.originalFileName,
                        status: 'done',
                        url: response.data.link,
                    },
                ]);
            });
        }
        if (data?.speakerDto?.avatar) {
            FileService.getFileData(data.speakerDto.avatar).then((response) => {
                setSpeakerFileList([
                    ...[],
                    {
                        uid: response.data.id,
                        name: response.data.originalFileName,
                        status: 'done',
                        url: response.data.link,
                    },
                ]);
            });
        }
    };

    useEffect(() => {
        setInitValues();
    }, [data]);

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            // eslint-disable-next-line no-param-reassign
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const onValuesChange = () => {
        setEditEvent(true);
    };

    const createEvent = (formData: IEvent) => {
        const newFormData = normalizeFormData(formData);
        EventService.postEvent(newFormData).then(() => {
            message.success('Мероприятие успешно добавлено!');
            navigate('/events');
        });
    };

    const updateEvent = (formData: IEvent) => {
        const newFormData = normalizeFormData(formData);

        if (data?.id) {
            EventService.putEvent(data?.id, newFormData).then(() => {
                message.success('Мероприятие успешно изменено!');
                navigate('/events');
            });
        }
    };

    const onFinishFailed = () => {
        message.error('error');
    };

    const onReset = () => {
        setInitValues();
        setEditEvent(false);
        form.resetFields();
    };

    const handleChangeApp: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setAppFileList(newFileList);
    };

    const handleChangeSite: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setSiteFileList(newFileList);
    };

    const handleChangeAvatar: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setEditEvent(true);
        setSpeakerFileList(newFileList);
    };

    const renderPrices = () => {
        return roles?.map((role, index) => {
            return (
                <Form.Item
                    label={`Цена для\n${role.value}`}
                    key={`key_${index.toString()}`}
                    name={['ticketPrices', index, role.value]}
                    initialValue={
                        data?.ticketPrices.filter((price) => price.role === role.value)[0]
                            ?.ticketPrice
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Введите корректную цену',
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
            );
        });
    };

    const uploadAvatarImage = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        const requestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (event) => {
                onProgress({ percent: (event.loaded / event.total) * 100 }, file);
            },
        };

        fmData.append('file', file);

        FileService.uploadPhoto(fmData, requestConfig)
            .then((newFile) => {
                onSuccess(file);
                setAvatarImgId(newFile.data.id);
            })
            .catch((err) => {
                const error = new Error(err);
                onError({ event: error });
            });
    };

    const uploadSiteImage = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        const requestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (event) => {
                onProgress({ percent: (event.loaded / event.total) * 100 }, file);
            },
        };

        fmData.append('file', file);

        FileService.uploadPhoto(fmData, requestConfig)
            .then((newFile) => {
                setSiteImgId(newFile.data.id);
                onSuccess(file);
            })
            .catch((err) => {
                const error = new Error(err);
                onError({ event: error });
            });
    };

    const uploadAppImage = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        const requestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (event) => {
                onProgress({ percent: (event.loaded / event.total) * 100 }, file);
            },
        };

        fmData.append('file', file);

        FileService.uploadPhoto(fmData, requestConfig)
            .then((newFile) => {
                setAppImgId(newFile.data.id);
                onSuccess(file);
            })
            .catch((err) => {
                const error = new Error(err);
                onError({ event: error });
            });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const [checkedSelectSiteMarkdown, checkSelectedSiteMarkdown] = useState<ISelectMarkdownOptions>(
        {
            bold: false,
            italicBold: false,
            italic: false,
        }
    );

    const [checkedSelectAppMarkdown, checkSelectedAppMarkdown] = useState<ISelectMarkdownOptions>({
        bold: false,
        italicBold: false,
        italic: false,
    });

    const [selectedText, setSelectedText] = useState<{
        selectionStart: number;
        selectionEnd: number;
    }>({
        selectionStart: 0,
        selectionEnd: 0,
    });

    const handleSelectDescription = (name: string, checkedSelectMarkdown) => {
        return ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>) => {
            const descriptionValue: string = form.getFieldValue(name);
            const { selectionStart, selectionEnd } = currentTarget;
            const markdownState = markdownChecker(
                descriptionValue.slice(selectionStart, selectionEnd)
            );
            setSelectedText({ selectionStart, selectionEnd });
            checkedSelectMarkdown(markdownState);
        };
    };

    const handleSelectSiteDescription = handleSelectDescription(
        'descriptionSite',
        checkSelectedSiteMarkdown
    );

    const handleSelectAppDescription = handleSelectDescription(
        'descriptionApp',
        checkSelectedAppMarkdown
    );

    const handleTextFormat = (callback: (value: string, markdown?: boolean) => string) => {
        return (name: string, markdown?: boolean) => {
            changeFormFieldValue(form, name, (descriptionValue: string) => {
                const { selectionEnd, selectionStart } = selectedText;
                const selectedDescription = descriptionValue.slice(selectionStart, selectionEnd);
                return (
                    descriptionValue.slice(0, selectedText.selectionStart) +
                    callback(selectedDescription, markdown) +
                    descriptionValue.slice(selectedText.selectionEnd)
                );
            });
        };
    };

    const handleAddLink = handleTextFormat((selectedDescription) => {
        return makeMarkdownLink(selectedDescription);
    });

    const handleMakeBold = handleTextFormat((selectedDescription, markdown) => {
        return markdown
            ? removeBoldFormatting(selectedDescription)
            : makeMarkdownBold(selectedDescription);
    });

    const handleMakeItalic = handleTextFormat((selectedDescription, markdown) => {
        return markdown
            ? removeItalicFormatting(selectedDescription)
            : makeMarkdownItalic(selectedDescription);
    });

    return (
        <Block
            className="app-block"
            loading={loading}
            title={title}
            description={`id: ${data?.id || ''}`}
            buttons={
                editEvent ? (
                    <Row style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                        <Button onClick={onReset}>Отмена</Button>
                        <Button type="primary" onClick={() => form.submit()}>
                            {isNew ? 'Создать' : 'Сохранить'}
                        </Button>
                    </Row>
                ) : null
            }
        >
            {(data || isNew) && (
                <Form
                    form={form}
                    name="main"
                    size="small"
                    layout="vertical"
                    wrapperCol={{ span: 20 }}
                    initialValues={{
                        name: data?.name,
                        organizer: data?.organizer,
                        descriptionSite: data?.descriptionSite,
                        descriptionApp: data?.descriptionApp,
                        important,
                        secret,
                        singleLink,
                        requiredPersonalData,
                        ticketTotalAmount: data?.ticketTotalAmount,
                        ticketPrices: data?.ticketPrices,
                        datetimeStart: dayjs(data?.datetimeStart.replace('Z', '')).tz(tz),
                        datetimeEnd: dayjs(data?.datetimeEnd.replace('Z', '')).tz(tz),
                        imageSiteId: data?.imageSiteId || '',
                        imageAppId: data?.imageAppId || '',
                        ticketAvailableAmount: data?.ticketAvailableAmount,
                        floor: data?.floor,
                        roomName: data?.roomName,
                        speakerDto: data?.speakerDto,
                        customRegistration: data?.customRegistration,
                    }}
                    onFinishFailed={onFinishFailed}
                    onFinish={isNew ? createEvent : updateEvent}
                    onValuesChange={onValuesChange}
                    autoComplete="off"
                >
                    <Row gutter={16} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Основная информация">
                                <Row style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                                    <Form.Item
                                        label="Дата начала"
                                        name="datetimeStart"
                                        rules={[{ required: true, message: 'Введите дату начала' }]}
                                    >
                                        <DatePicker
                                            locale={config.ru}
                                            showTime
                                            showSecond={false}
                                            format={config.dates.def}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Дата окончания"
                                        name="datetimeEnd"
                                        rules={[
                                            { required: true, message: 'Введите дату окончания' },
                                        ]}
                                    >
                                        <DatePicker
                                            locale={config.ru}
                                            showTime
                                            showSecond={false}
                                            format={config.dates.def}
                                        />
                                    </Form.Item>
                                </Row>
                                <Form.Item
                                    label="Наименование"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите наименование мероприятия',
                                        },
                                        { max: 100, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Организатор"
                                    name="organizer"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите наименование организатора',
                                        },
                                        { max: 50, message: 'Максимальная длинна 50 символов' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Row
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 10,
                                        maxWidth: 650,
                                    }}
                                >
                                    <Form.Item
                                        label="Аватар спикера"
                                        name={['speakerDto', 'avatar']}
                                        getValueFromEvent={normFile}
                                    >
                                        <ImgCrop>
                                            <Upload
                                                accept=".jpg, .jpeg, .png"
                                                listType="picture-card"
                                                fileList={speakerFileList}
                                                onChange={handleChangeAvatar}
                                                onPreview={handlePreview}
                                                customRequest={uploadAvatarImage}
                                                onRemove={handleAvatarImgRemove}
                                            >
                                                {speakerFileList.length >= 1
                                                    ? null
                                                    : 'Загрузить аватар'}
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    <Col style={{ maxWidth: 520, width: 220 }}>
                                        <Form.Item
                                            label="Имя спикера"
                                            name={['speakerDto', 'name']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Введите имя спикера',
                                                },
                                                {
                                                    max: 50,
                                                    message: 'Максимальная длинна 50 символов',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Описание спикера"
                                            name={['speakerDto', 'description']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Введите описание спикера',
                                                },
                                                {
                                                    max: 200,
                                                    message: 'Максимальная длинна 200 символов',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea rows={4} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Место проведения">
                                <Row
                                    style={{
                                        display: 'flex',
                                        maxWidth: 600,
                                    }}
                                >
                                    <Form.Item
                                        style={{
                                            flexGrow: 4,
                                        }}
                                        label="Помещение"
                                        name="roomName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите название помещения',
                                            },
                                            {
                                                max: 50,
                                                message: 'Максимальная длинна 100 символов',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            flexGrow: 1,
                                        }}
                                        label="Этаж"
                                        name="floor"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Выберите этаж помещения',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={floors?.map((floor) => {
                                                return { value: floor.value, label: floor.label };
                                            })}
                                        />
                                    </Form.Item>
                                </Row>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Цены">
                                <div className="price-wrapper">{renderPrices()}</div>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Билеты">
                                <Row
                                    style={{
                                        display: 'flex',
                                        gap: 30,
                                    }}
                                >
                                    <Form.Item
                                        label="Количество билетов"
                                        name="ticketTotalAmount"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите корректную цену',
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                    {!isNew && (
                                        <Form.Item
                                            label="Доступно билетов"
                                            name="ticketAvailableAmount"
                                        >
                                            <InputNumber disabled />
                                        </Form.Item>
                                    )}
                                </Row>
                            </InnerBlock>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={12}
                            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                        >
                            <InnerBlock loading={loading} title="Обложки">
                                <Space size="large" style={{ display: 'flex' }}>
                                    <Form.Item
                                        name="imageSiteId"
                                        label="Обложка для сайта"
                                        tooltip={false}
                                        rules={[
                                            { required: true, message: 'Выберите изображение' },
                                        ]}
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload
                                            accept=".jpg, .jpeg, .png"
                                            listType="picture-card"
                                            fileList={siteFileList}
                                            onChange={handleChangeSite}
                                            onPreview={handlePreview}
                                            customRequest={uploadSiteImage}
                                            onRemove={handleSiteImgRemove}
                                        >
                                            {siteFileList.length >= 1
                                                ? null
                                                : 'Загрузить изображение'}
                                        </Upload>
                                    </Form.Item>

                                    <Form.Item
                                        name="imageAppId"
                                        label="Обложка для приложения"
                                        rules={[
                                            { required: true, message: 'Выберите изображение' },
                                        ]}
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload
                                            accept=".jpg, .jpeg, .png"
                                            listType="picture-card"
                                            fileList={appFileList}
                                            onChange={handleChangeApp}
                                            onPreview={handlePreview}
                                            customRequest={uploadAppImage}
                                            onRemove={handleAppImgRemove}
                                        >
                                            {appFileList.length >= 1
                                                ? null
                                                : 'Загрузить изображение'}
                                        </Upload>
                                    </Form.Item>
                                </Space>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Описания">
                                <Form.Item
                                    label="Описание для сайта"
                                    name="descriptionSite"
                                    rules={[
                                        { required: true, message: 'Введите описание' },
                                        { max: 1024, message: 'Максимальная длинна 1000 символов' },
                                    ]}
                                    extra={
                                        <Row style={{ display: 'flex', gap: '10px' }}>
                                            <Button
                                                className="button"
                                                onClick={() => handleAddLink('descriptionSite')}
                                            >
                                                {link} Добавить ссылку
                                            </Button>
                                            <Button
                                                className="button"
                                                type={
                                                    checkedSelectSiteMarkdown.bold
                                                        ? 'dashed'
                                                        : 'default'
                                                }
                                                onClick={() =>
                                                    handleMakeBold(
                                                        'descriptionSite',
                                                        checkedSelectSiteMarkdown.bold
                                                    )
                                                }
                                            >
                                                <BoldOutlined />
                                            </Button>
                                            <Button
                                                className="button"
                                                type={
                                                    checkedSelectSiteMarkdown.italic
                                                        ? 'dashed'
                                                        : 'default'
                                                }
                                                onClick={() =>
                                                    handleMakeItalic(
                                                        'descriptionSite',
                                                        checkedSelectSiteMarkdown.italic
                                                    )
                                                }
                                            >
                                                <ItalicOutlined />
                                            </Button>
                                        </Row>
                                    }
                                >
                                    <Input.TextArea
                                        onSelect={handleSelectSiteDescription}
                                        rows={5}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Описание для приложения"
                                    name="descriptionApp"
                                    rules={[
                                        { required: true, message: 'Введите описание' },
                                        { max: 1024, message: 'Максимальная длинна 1000 символов' },
                                    ]}
                                    extra={
                                        <Row style={{ display: 'flex', gap: '10px' }}>
                                            <Button
                                                className="button"
                                                onClick={() => handleAddLink('descriptionApp')}
                                            >
                                                {link} Добавить ссылку
                                            </Button>
                                            <Button
                                                className="button"
                                                type={
                                                    checkedSelectAppMarkdown.bold
                                                        ? 'dashed'
                                                        : 'default'
                                                }
                                                onClick={() =>
                                                    handleMakeBold(
                                                        'descriptionApp',
                                                        checkedSelectAppMarkdown.bold
                                                    )
                                                }
                                            >
                                                <BoldOutlined />
                                            </Button>
                                            <Button
                                                className="button"
                                                type={
                                                    checkedSelectAppMarkdown.italic
                                                        ? 'dashed'
                                                        : 'default'
                                                }
                                                onClick={() =>
                                                    handleMakeItalic(
                                                        'descriptionApp',
                                                        checkedSelectAppMarkdown.italic
                                                    )
                                                }
                                            >
                                                <ItalicOutlined />
                                            </Button>
                                        </Row>
                                    }
                                >
                                    <Input.TextArea
                                        onSelect={handleSelectAppDescription}
                                        rows={5}
                                    />
                                </Form.Item>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Доп.">
                                <Form.Item label="Важное" name="important">
                                    <Switch
                                        checked={important}
                                        onChange={() => {
                                            setImportant(!important);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Закрытое мероприятие" name="secret">
                                    <Switch
                                        checked={secret}
                                        onChange={() => {
                                            setSecret(!secret);
                                            setSingleLink(false);
                                        }}
                                        disabled={!isNew}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Сгенерировать многоразовую ссылку"
                                    name="singleLink"
                                >
                                    <Switch
                                        checked={singleLink}
                                        onChange={() => {
                                            setSingleLink(!singleLink);
                                        }}
                                        disabled={!secret || !isNew}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Запрашивать личные данные"
                                    name="requiredPersonalData"
                                >
                                    <Switch
                                        checked={requiredPersonalData}
                                        onChange={() => {
                                            setRequiredPersonalData((prevState) => !prevState);
                                        }}
                                    />
                                </Form.Item>
                            </InnerBlock>
                            <InnerBlock loading={loading} title="Регистрация через партнера">
                                <Form.Item name={['customRegistration', 'used']}>
                                    <Switch
                                        checked={customRegistration}
                                        onChange={setCustomRegistration}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Ссылка на регистрацию"
                                    name={['customRegistration', 'link']}
                                    rules={[
                                        {
                                            required: customRegistration,
                                            message: 'Введите ссылку на регистрацию',
                                        },
                                        {
                                            pattern:
                                                /^https?:\/\/([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/gm,

                                            message: 'Значение поля не является ссылкой',
                                        },
                                    ]}
                                >
                                    <Input disabled={!customRegistration} />
                                </Form.Item>
                            </InnerBlock>
                        </Col>
                    </Row>
                </Form>
            )}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Block>
    );
};

export default EventInfo;
